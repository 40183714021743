import client1Logo from 'images/clients/1.webp';
import client2Logo from 'images/clients/2.webp';
import client3Logo from 'images/clients/3.webp';
import client4Logo from 'images/clients/4.webp';
import client5Logo from 'images/clients/5.webp';
import client6Logo from 'images/clients/6.webp';
import client7Logo from 'images/clients/7.webp';
import client8Logo from 'images/clients/8.webp';
import client9Logo from 'images/clients/9.webp';
import client10Logo from 'images/clients/10.webp';
import client11Logo from 'images/clients/11.webp';

export const CALENDLY_LINK = 'https://calendly.com/adeel-sixsigmatradex';

export const navigationLinks = [
  {
    title: 'home',
    href: '/',
  },
  {
    title: 'about',
    href: '/about',
  },
  {
    title: 'services',
    href: '/#section-services',
  },
];

export const clientLogos = [
  client1Logo,
  client2Logo,
  client3Logo,
  client4Logo,
  client5Logo,
  client6Logo,
  client7Logo,
  client8Logo,
  client9Logo,
  client10Logo,
  client11Logo,
];
